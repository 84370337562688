import {createContext, useContext, useEffect, useReducer, useRef, useState} from "react";
import {db} from "../firebase/init";
import {onSnapshot, doc} from "@firebase/firestore";
import {useUser} from "./useUser";
import addNotification from 'react-push-notification';
import {isEqual} from "lodash";

const reducer = (state, action) => {
    if (action.type === undefined) {
        return state;
    }
    const {incomeItems, expenseItems, investmentItems} = state;
    const currentDate = new Date();
    const formattedCurrentYear = new Intl.DateTimeFormat('en-us', {year: 'numeric'}).format(currentDate);
    const formattedCurrentMonth = new Intl.DateTimeFormat('en-us', {month: '2-digit'}).format(currentDate);
    const formattedCurrentDay = new Intl.DateTimeFormat('en-us', {day: '2-digit'}).format(currentDate);
    const formattedCurrentDate = formattedCurrentYear + '-' + formattedCurrentMonth + '-' + formattedCurrentDay

    let newIncomeTotal = 0,
        newExpenseTotal = 0,
        newInvestmentTotal = 0,
        newBalance = 0,
        newItems = null,
        newItem = null,
        itemObj = {
            name: '',
            type: '',
            amount: 0
        };

    function getTotal(items) {
        let total = 0;
        if (items === undefined || items.length <= 0) {
            return parseFloat(total);
        }
        items.map((item) => {
            total += parseFloat(item.amount);
            return item;
        })
        return total;
    }

    switch (action.type) {
        case 'updateTotals':
            newIncomeTotal = getTotal(incomeItems);
            newExpenseTotal = getTotal(expenseItems);
            newInvestmentTotal = getTotal(investmentItems);
            newBalance = parseFloat(newIncomeTotal - newExpenseTotal - newInvestmentTotal);
            return {
                ...state,
                incomeTotal: newIncomeTotal,
                expenseTotal: newExpenseTotal,
                investmentTotal: newInvestmentTotal,
                balance: newBalance
            };
        case 'addItems':
            let itemUpdateState = action.itemState;
            return {...state, ...itemUpdateState}
        case 'addIncomeItem':
            itemObj = action.itemObj;
            newItem = {
                name: itemObj.itemName,
                type: itemObj.itemType,
                amount: itemObj.itemAmount,
                date: formattedCurrentDate
            }
            newItems = [...incomeItems, newItem];
            return {
                ...state,
                incomeItems: newItems,
            }
        case 'addExpenseItem':
            itemObj = action.itemObj;
            newItem = {
                name: itemObj.itemName,
                type: itemObj.itemType,
                amount: itemObj.itemAmount,
                date: formattedCurrentDate
            }
            newItems = [...expenseItems, newItem];
            return {
                ...state,
                expenseItems: newItems,
            }
        case 'addInvestmentItem':
            itemObj = action.itemObj;
            newItem = {
                name: itemObj.itemName,
                type: itemObj.itemType,
                amount: itemObj.itemAmount,
                date: formattedCurrentDate
            }
            newItems = [...investmentItems, newItem];
            return {
                ...state,
                investmentItems: newItems,
            }
        case 'removeIncomeItem':
            if (state.incomeItems !== undefined) {
                let newIncomeItems = state.incomeItems.filter((value, index) => {
                    return index !== action.itemIndex;
                });
                return {...state, incomeItems: newIncomeItems};
            }
            return state;
        case 'removeInvestmentItem':
            if (state.investmentItems !== undefined) {
                let newInvestmentItems = state.investmentItems.filter((value, index) => {
                    return index !== action.itemIndex;
                });
                return {...state, investmentItems: newInvestmentItems};
            }
            return state;
        case 'removeExpenseItem':
            if (expenseItems !== undefined) {
                let newExpenseItems = expenseItems.filter((value, index) => {
                    return index !== action.itemIndex;
                });
                return {...state, expenseItems: newExpenseItems};
            }
            return state;
        case 'updateItems':
            if (action.itemType === undefined)
                return state;
            let oldItems = [];
            if (action.itemType === 'income') {
                oldItems = incomeItems;
            } else if (action.itemType === 'expense') {
                oldItems = expenseItems;
            } else if (action.itemType === 'investment') {
                oldItems = investmentItems;
            } else {
                return state;
            }

            newItems = oldItems.map((item, index) => {
                if (index === action.itemId) {
                    item = action.itemObj;
                }
                return item;
            });
            if (action.itemType === 'income') {
                return {...state, incomeItems: newItems}
            } else if (action.itemType === 'expense') {
                return {...state, expenseItems: newItems}
            } else if (action.itemType === 'investment') {
                return {...state, investmentItems: newItems}
            } else {
                return state;
            }
            break;
        default:
            return state;
    }
};
const CalculatorContext = createContext();

export function CalculatorContextProvider({children}) {
    const [appState, dispatch] = useReducer(reducer, {
        incomeTotal: 0,
        expenseTotal: 0,
        investmentTotal: 0,
        balance: 0,
        incomeItems: [],
        expenseItems: [],
        investmentItems: []
    });

    //@todo: probably need to move this to a prop
    const currentDate = new Date();
    const initialYear = new Intl.DateTimeFormat('en-us', {year: 'numeric'}).format(currentDate);
    const initialMonth = new Intl.DateTimeFormat('en-us', {month: '2-digit'}).format(currentDate);

    const [currentAppDate, setCurrentAppDate] = useState({
        year: initialYear,
        month: initialMonth
    });

    const {user} = useUser();
    const {userDetails} = user;

    const addIncomeItem = (itemObj) => {
        dispatch({
            type: 'addIncomeItem',
            itemObj
        })
    }
    const addInvestmentItem = (itemObj) => {
        dispatch({
            type: 'addInvestmentItem',
            itemObj
        })
    }
    const addExpenseItem = (itemObj) => {
        dispatch({
            type: 'addExpenseItem',
            itemObj
        })
    }
    const removeIncomeItem = (itemIndex) => {
        dispatch({
            type: 'removeIncomeItem',
            itemIndex
        })
    }
    const removeInvestmentItem = (itemIndex) => {
        dispatch({
            type: 'removeInvestmentItem',
            itemIndex
        })
    }
    const removeExpenseItem = (itemIndex) => {
        dispatch({
            type: 'removeExpenseItem',
            itemIndex
        })
    }
    const updateItems = (itemType, itemId, itemObj) => {
        dispatch({
            type: 'updateItems',
            itemId: itemId,
            itemType: itemType,
            itemObj: itemObj
        })
    }
    const updateBalance = () => {
        dispatch({type: 'updateBalance'})
    }
    const calculatorLoaded = useRef(null);

    const showDataForDate = (year, month) => {
        if (
            (year === '' || year === undefined) &&
            (month === '' || month === undefined)

        ) {
            alert('Both Year and Month is Required')
        } else {
            setCurrentAppDate({year, month})
        }

    }

    useEffect(() => {
        const {year, month} = currentAppDate;
        if (userDetails !== undefined && userDetails !== null) {
            const unsub = onSnapshot(doc(db, "users", `${userDetails.uid}/Year/${year}/Month/${month}`), (doc) => {
                let data = doc.data();
                // console.log(doc.);
                if (data !== undefined) {
                    let retrievedIncomeItems = data.incomeItems !== undefined ? data.incomeItems : [];
                    let retrievedExpenseItems = data.expenseItems !== undefined ? data.expenseItems : [];
                    let retrieveInvestmentItems = data.investmentItems !== undefined ? data.investmentItems : [];

                    let itemState = {
                        incomeItems: retrievedIncomeItems,
                        expenseItems: retrievedExpenseItems,
                        investmentItems: retrieveInvestmentItems
                    }

                    const notificationMessage = {
                        'title': '',
                        'message':'',
                    };
                    
                    if( calculatorLoaded.current ){
                        if (!isEqual(retrievedIncomeItems, appState.incomeItems)) {
                            notificationMessage.title = 'Income Updated';
                            notificationMessage.message = 'Income Items Updated';
                        }

                        if (!isEqual(retrievedExpenseItems, appState.expenseItems)) {
                            notificationMessage.title = 'Expense Updated';
                            notificationMessage.message = 'Expense Items Updated';
                        }

                        if (!isEqual(retrieveInvestmentItems, appState.investmentItems)) {
                            notificationMessage.title = 'Investment Updated';
                            notificationMessage.message = 'Investment Items Updated';
                        }
                        
                        if(notificationMessage.title !== ''){
                            addNotification({
                                title: notificationMessage.title,
                                subtitle: 'This is a subtitle',
                                message: notificationMessage.message,
                                theme: 'darkblue',
                                native: true // when using native, your OS will handle theming.
                            });
                        }
                        
                    }


                    dispatch({type: 'addItems', itemState})
                    calculatorLoaded.current = 'loaded';
                }


            });
            return unsub;
        }

    }, [userDetails, currentAppDate])

    useEffect(() => {
        dispatch({type: 'updateTotals'})
    }, [
        appState.incomeItems,
        appState.expenseItems,
        appState.investmentItems
    ])

    return (
        <CalculatorContext.Provider value={{
            appState,
            currentAppDate,
            showDataForDate,
            updateBalance,
            addIncomeItem,
            addExpenseItem,
            addInvestmentItem,
            removeIncomeItem,
            removeExpenseItem,
            removeInvestmentItem,
            updateItems
        }}>
            {children}
        </CalculatorContext.Provider>
    );
}

//custom hook
export const useCalculator = () => {
    return useContext(CalculatorContext);
}


