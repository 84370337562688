import IncomeExpenseCalculator from "./income-expense-calculator"

import './index.css';
import {CalculatorContextProvider} from "./context/calculatorContextProvider";
import LoginForm from "./login-components/LoginForm";
import {useUser} from "./context/useUser";
import {Notifications} from 'react-push-notification';


function App() {
    const {user} = useUser();
    return (
        <div className="App">
            <Notifications/>
            <>
                {!user.isLoggedIn && <LoginForm/>}
                {user.isLoggedIn &&
                <CalculatorContextProvider>
                    <IncomeExpenseCalculator/>
                </CalculatorContextProvider>
                }

            </>

        </div>
    );
}

export default App;
