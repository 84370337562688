import React, {useState} from "react";
import ListItem from "./ListItem";


const BalanceMaintainer = (props) => {
    const [formDetails, setFormDetails] = useState({
        itemName: '',
        itemAmount: '',
        itemType: props.title.toLowerCase() === 'investment' ? 'investment' : ''
    });
    const [showForm, setShowForm] = useState(false);
    const toggleShowForm = (e) => {
        e.preventDefault();
        setShowForm((prevShowForm) => {
            return !prevShowForm;
        })
    }
    const handleOnChange = (e) => {
        const {name, value} = e.target;

        setFormDetails(prevFormDetails => {
            const newFormDetails = {
                [name]: value
            }
            return {...prevFormDetails, ...newFormDetails};
        });


    };
    const handleSubmit = (e) => {
        e.preventDefault();
        //update total -- goes two level updates
        props.setItems(formDetails);

        //clear form after
        setFormDetails({
            itemName: '',
            itemAmount: '',
            itemType: props.title.toLowerCase() === 'investment' ? 'investment' : ''
        });

    };
    const currentListItems = () => {
        let currentItems = null;
        if (typeof currentItems != "undefined" && props.items.length > 0) {
            currentItems = props.items.map((value, index) => {
                return <ListItem
                    key={index}
                    id={index}
                    date={value.date}
                    name={value.name}
                    type={value.type}
                    amount={value.amount}
                    itemType={props.title.toLowerCase()}
                    itemRemovable={true}
                    itemEditable={true}
                    removeItem={props.removeItem}
                />
            });
        }

        return currentItems;
    };
    const showCurrentItems = () => {
        if (typeof props.items != "undefined" && props.items.length > 0) {
            return (
                <ul className="items">
                    {currentListItems()}
                </ul>);
        } else {
            return (
                <div className="no-items-found">
                    <h2 className={"text-center p-2"}>No {props.title} added</h2>
                </div>

            );
        }
    }

    return (
        <>
            <div className="flex items-center mb-2.5">
                <div className="w-8/12"><h2>{props.title} Column</h2></div>
                <div className="w-4/12 text-right">
                    <a href={"/"} className={"bg-gray-300 hover:bg-gray-100 text-black p-1.5"} onClick={toggleShowForm}>{
                        !showForm ? 'Add Item' : 'Cancel'}</a>
                </div>
            </div>
            {showForm && <form action="" className={`mb-2.5 text-black`} method="post" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="mb-3">
                        <input type="text"
                               id="itemName"
                               name="itemName"
                               className="form-control"
                               placeholder="Item Name"
                               onChange={handleOnChange}
                               value={formDetails.itemName}
                               required="required"
                        />
                    </div>
                    {
                        props.title.toLowerCase() !== 'investment' &&
                        <div>
                            <select className='form-select'
                                    name="itemType"
                                    id="itemType"
                                    required="required"
                                    onChange={handleOnChange}
                                    value={formDetails.itemType}>
                                <option value={""}>Select</option>
                                <option value="monthly">Monthly</option>
                                <option value="weekly">Weekly</option>
                                <option value="daily">Daily</option>
                            </select>
                        </div>
                    }

                    <div className="mt-3">
                        <input type="number"
                               id="itemAmount"
                               name="itemAmount"
                               className="form-control"
                               placeholder="Amount"
                               onChange={handleOnChange}
                               value={formDetails.itemAmount}
                               required="required"
                        />
                    </div>
                    <div className="mt-3">
                        <button type="submit" className="btn bg-black text-white w-full">Add</button>
                    </div>
                </div>
            </form>}

            <div className="balance-items flex-col border-t-2 border-white pt-3">
                <ul className="items item-header">
                    <li className={"flex justify-around"}>
                        <span className="list-date w-2/12 sm:w-1/6 text-center"><strong>Date</strong></span>
                        <span className="list-name w-1/6 sm:w-1/4"><strong>Name</strong></span>
                        <span className="item-type sm:block w-1/5 sm:w-1/5 hidden sm:block"><strong>Type</strong></span>
                        <span className="list-amount w-1/4 sm:w-1/5"><strong>Amount</strong></span>
                        <span>Actions</span>
                    </li>
                </ul>

                {showCurrentItems()}
            </div>
            <div className={"w-full mt-auto"}>
                <ul className="items item-header mt-3 border-t-2 border-white pt-2.5 bottom">
                    <li className={"flex"}>
                        <span className="list-name w-1/2"><strong>Total {props.title}</strong></span>
                        {/*<span className="item-type w-1/4">---</span>*/}
                        <span className="list-amount w-1/2 text-right"><strong>{props.balance}</strong></span>
                    </li>
                </ul>
            </div>
           
        </>
    );
}

export default BalanceMaintainer;