import React, {useState} from "react";
import ListItem from "./ListItem";
import {useCalculator} from "../context/calculatorContextProvider";
import BalanceMaintainer from "./BalalanceMaintainer";

//lets make it so that this only shows this months 
//we cant have previous months assets showing up here
//since we are removing this item we have to be able to keep it item in history
//so in the app we should distinguish between past investments and this new one
const SavingReInvest = ({balance, showWhen}) => {

    // const calculatorContext = useCalculator();
    const {appState, removeInvestmentItem, addInvestmentItem} = useCalculator();
    const title = "Investment";

    const setItems = (itemObj) => {
        addInvestmentItem(itemObj);
    }

    const removeItemHandler = (itemIndex) => {
        removeInvestmentItem(itemIndex);
    }
    return (
        <>
            {
                ((balance >= showWhen) || appState.investmentItems.length > 0) &&
                <>
                    <p className={"mb-5"}>You have some savings - {balance} - do you want to re-invest the savings ?</p>
                    <BalanceMaintainer
                        balance={appState.investmentTotal}
                        title={title}
                        items={appState.investmentItems}
                        removeItem={removeItemHandler}
                        setItems={setItems}
                    />
                </>
                    
            }
        </>
    )

}

export default SavingReInvest;