import {useState} from "react";
import {useUser} from "../context/useUser";
import UserResetPassword from "../user/userResetPassword";

const LoginForm = () => {

    const {user, loginHandler} = useUser();

    const [formFields, setFormFields] = useState({
        'email': '',
        'password': ''
    });

    const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);

    const [message, setMessage] = useState('');

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        setFormFields(prevFormFields => {
            const newFormDetails = {
                [name]: value
            }
            return {...prevFormFields, ...newFormDetails};
        });


    };
    const submitHandler = async (e) => {
        setMessage('');
        e.preventDefault();
        const {email, password} = formFields;
        const result = await loginHandler(email, password);
        if (!result.status) {
            setMessage('User name + password incorrect');
        } else {
            setMessage('Succefully Logged In');
        }
    }
    const toggleShowPasswordResetForm = (e) => {
        e.preventDefault();
        setShowPasswordResetForm((prevShowPasswordResetForm) => {
            return !prevShowPasswordResetForm;
        })
    }

    if (user.isLoggedIn) {
        return '';
    }

    return (
        <div className={"absolute text-black top-1/2 left-1/2 login-form w-10/12 sm:w-1/2 md:w-1/4 2xl:w-1/5 bg-white p-5 lg:p-10 rounded-lg"}>
            {
                message &&
                <div className="bg-white text-red-400 text-center p-1.5 rounded mb-5">
                    <p>{message}</p>
                </div>
            }

            {!showPasswordResetForm && <form action={""} method={"post"} onSubmit={submitHandler}>
                <h1>Login to continue</h1>
                <div className="flex mt-3 flex-col gap-2">
                    <div className={"w-full"}>
                        <input
                            type={"email"}
                            name={"email"}
                            value={formFields.email}
                            placeholder={"Email"}
                            onChange={onChangeHandler}
                            className={"border-black border-2 rounded" }
                        />
                    </div>
                    <div className={"w-full"}>
                        <input type={"password"}
                               name={"password"}
                               value={formFields.password}
                               placeholder={"Password"}
                               className={"border-black border-2 rounded" }
                               onChange={onChangeHandler}
                        />
                    </div>
                </div>
                <div className={"flex-auto gap-3 mt-2.5"}>
                    <div className={"w-full"}>
                        <input type={"submit"}
                               name={"submit"}
                               value={"Login"}
                               className={"bg-black rounded text-white transform transition-transform hover:-translate-y-1"}
                        />
                    </div>
                    <div className={"w-full mt-3"}>
                        <a href={"/"} type={"button"}
                               value={"Forgot Password"}
                               className={"hover:underline"}
                               onClick={toggleShowPasswordResetForm}
                        >Forgot Password ?</a>
                    </div>
                </div>
            </form>}
            {showPasswordResetForm && <UserResetPassword/>}
            {
                showPasswordResetForm &&
                <div className={"flex mt-2.5"}>
                    <a href={"/"} className={"hover:underline"} onClick={toggleShowPasswordResetForm}>Back to Login</a>
                </div>
            }


        </div>
    );
}

export default LoginForm;