import {useUser} from "../context/useUser";

const LogoutButton = () => {
    const {logOutUser} = useUser();
    const logoutHandler = (e) => {
        e.preventDefault();
        logOutUser();
    }

    return (
            <input 
                type={"button"} 
                className={"btn bg-black text-white rounded-2xl"} 
                value={"logout"} 
                onClick={logoutHandler}/>
    );
}

export default LogoutButton;