import BalanceMaintainer from "./BalalanceMaintainer";
import React from "react";
import {useCalculator} from "../context/calculatorContextProvider";

const IncomeCalculator = (props) => {
    // const calculatorContext = useCalculator();
    const {appState, removeIncomeItem,addIncomeItem} = useCalculator();
    const title = "Income";

    const setItems = (itemObj) => {
        addIncomeItem(itemObj);
    }

    const removeItemHandler = (itemIndex) => {
        removeIncomeItem(itemIndex);
    }
    return (
        <BalanceMaintainer
            balance={appState.incomeTotal}
            title={title}
            items={appState.incomeItems}
            removeItem={removeItemHandler}
            setItems={setItems}
        />
    );
}
export default IncomeCalculator;