import React, {useState} from "react";
import SavingReInvest from "./SavingReInvest";
import IncomeCalculator from "./IncomeCalculator";
import ExpenseCalculator from "./ExpenseCalculator";
import {useCalculator} from "../context/calculatorContextProvider";
import {useUser} from "../context/useUser";
import LogoutButton from "../login-components/LogoutButton";
import History from './History/History';
import {db} from '../firebase/init';
import {doc, setDoc} from '@firebase/firestore';
import {FaChevronDown, FaChevronUp, FaEllipsisV, FaUser, FaUserEdit, FaWindowClose} from 'react-icons/fa'
import UserProfile from "../user/UserProfile";

const IncomeExpenseCalculator = () => {
    const {user} = useUser();
    const {userDetails} = user;
    const {appState, currentAppDate, showDataForDate} = useCalculator();
    const {year, month} = currentAppDate;
    const currentDate = new Date();
    const currentYear = new Intl.DateTimeFormat('en-us', {year: 'numeric'}).format(currentDate);
    const currentMonth = new Intl.DateTimeFormat('en-us', {month: '2-digit'}).format(currentDate);
    const [savingCalculatorData, setSavingCalculatorData] = useState(false);
    const [showUserSettings, setShowUserSettings] = useState(false);
    const toggleShowUserSettings = (e) => {
        e.preventDefault();
        setShowUserSettings((prevShowUserSettings) => {
            return !prevShowUserSettings;
        })
    }


    const [showHistory, setShowHistory] = useState(false);
    const [showUserProfileForm, setShowUserProfileForm] = useState(false);


    const toggleHistory = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        setShowHistory((prevShowHistory) => {
            return !prevShowHistory;
        })
    };

    const handleHistorySelect = () => {
        toggleHistory();
    }

    const saveCurrentData = async () => {
        setSavingCalculatorData(true);
        const userDocumentURL = `${userDetails.uid}/Year/${year}/Month/${month}`;
        try {
            //console.log(appState);
            const res = await setDoc(doc(db, 'users', userDocumentURL), appState);
            console.log('Data added', res);
            setSavingCalculatorData(false);
        } catch (e) {
            console.log(e);
            setSavingCalculatorData(false);
            // console.log('Error', e.message());
        }

        // console.log(appSta
    }

    const toggleShowProfileForm = (e) => {
        e.preventDefault();
        setShowUserProfileForm((prevShowUserProfileForm) => {
            return !prevShowUserProfileForm;
        })
    }

    return (
        <>
            <div className="
            container 
            mx-auto 
            lg:w-3/4
            2xl:w-1/2 
            p-2.5  
            bg-gray-700
            sm:p-8
            sm:rounded-2xl 
            sm:transform
            md:mt-5
            lg:mt-8
            2xl:mt-20
            ">
                <div className={"shadow rounded-2xl border-2 border-indigo-300 sm:mb-5"}>
                    {user.isLoggedIn &&
                    <div className={"flex-col gap-5 p-2.5"}>
                        <a className={"flex"} href={"/"} onClick={toggleShowUserSettings}>
                            <div className="w-11/12 flex items-center">
                                <FaUser className={"inline-block mr-2"}/>
                                {userDetails.email}
                            </div>
                            <div className="w-1/12 text-right">

                                {
                                    !showUserSettings &&
                                    <FaEllipsisV className={"inline-block"}/>
                                }
                                {
                                    showUserSettings &&
                                    <FaWindowClose className={"inline-block"}/>
                                }

                            </div>
                        </a>
                        {
                            showUserSettings
                            &&
                            <div className={'w-full  rounded-lg p-2.5 mt-2.5'}>
                                <a href={'/'} className={"flex-shrink-0 block mb-2.5"} onClick={toggleShowProfileForm}>
                                    {
                                        !showUserProfileForm &&
                                        <div className={"flex"}>
                                            <div className={"w-9/12"}>Edit User Profile</div>
                                            <div className={"w-3/12 text-right"}>
                                                <FaUserEdit className={"inline-block"}/>
                                            </div>
                                        </div>
                                    }
                                    {
                                        showUserProfileForm &&
                                        <div className={"flex"}>
                                            <div className={"w-9/12"}>Cancel Editing User Profile</div>
                                            <div className={"w-3/12 text-right"}>
                                                <FaWindowClose className={"inline-block"}/>
                                            </div>
                                        </div>
                                    }
                                </a>
                                <div className="flex-col align-middle items-center mb-5">
                                    <UserProfile showForm={showUserProfileForm}/>
                                </div>
                                <LogoutButton/>
                            </div>
                        }
                    </div>
                    }
                </div>

                <div className="flex-grow-0 sm:flex mb-5 mt-2.5 gap-5">
                    <div className="flex w-full sm:w-1/2 p-2.5 border-2 border-white rounded-2xl">
                        <div className={"text-center sm:text-left"}>
                            <h1>Showing Details for : {year} / {month} </h1>
                        </div>
                        <div className={"sm:pl-5"}>
                            {
                                (currentMonth !== month || currentYear !== year)
                                && <input type="button"
                                          className={"bg-white text-black hover:text-white hover:bg-black"}
                                          value={"Reset to current date"}
                                          onClick={() => {
                                              showDataForDate(currentYear, currentMonth);
                                          }}
                                />
                            }
                        </div>
                    </div>
                    <div className={"history mt-2.5 sm:mt-0 flex-col w-full sm:w-1/2 p-2.5 border-2 border-white rounded-2xl "}>
                        <a href="/" className={"flex items-center"} onClick={toggleHistory}>
                            <div className={"w-11/12"}>
                                History
                            </div>
                            <div className={"w-1/12 text-right"}>
                                {!showHistory && <FaChevronDown className={"inline-block"}/>}
                                {showHistory && <FaChevronUp className={"inline-block"}/>}
                            </div>
                        </a>
                        {
                            showHistory
                            &&
                            <History onHistorySelect={handleHistorySelect}/>
                        }
                    </div>
                </div>
                <div className="sm:flex gap-4 mt-2.5">
                    <div className="p-2.5 flex flex-col border-2 border-blue-400 rounded-2xl sm:w-1/2 ">
                        <IncomeCalculator/>
                    </div>
                    <div className="mt-2.5 flex flex-col sm:mt-0 p-2.5 border-2 border-red-400 rounded-2xl sm:w-1/2">
                        <ExpenseCalculator/>
                    </div>
                </div>

                <div className="sm:flex gap-4 mt-2.5">
                    <div className={"flex flex-col sm:mt-0 p-2.5 border-2 border-yellow-400 rounded-2xl sm:w-1/2"}>
                        <SavingReInvest
                            balance={appState.balance}
                            showWhen={1000}
                        />
                    </div>
                    <div className="flex flex-col w-full sm:w-1/2 p-2.5 border-2 border-green-400 rounded-2xl mt-2.5 sm:mt-0">
                        <div className={"flex"}>
                            <div className="w-1/2">
                                Income:
                            </div>
                            <div className="w-1/2 text-right">
                                {appState.incomeTotal}
                            </div>
                        </div>
                        <div className={"flex"}>
                            <div className="w-1/2">
                                Expense:
                            </div>
                            <div className="w-1/2 text-right">
                                {appState.expenseTotal}
                            </div>
                        </div>
                        <div className={"flex"}>
                            <div className="w-1/2">
                                Investment:
                            </div>
                            <div className="w-1/2 text-right">
                                {appState.investmentTotal}
                            </div>
                        </div>
                        <div className={"flex mt-auto"}>
                            <div className="w-1/2">
                                Balance:
                            </div>
                            <div className="w-1/2 text-right">
                                {appState.balance}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="flex mt-2.5 mb-2.5 justify-end">
                    <input type={"button"}
                           className={"btn bg-black text-white sm:w-1/4 rounded-lg"}
                           value={!savingCalculatorData ? "Save" : "Saving..."}
                           onClick={saveCurrentData}
                           disabled={savingCalculatorData}
                    />
                </div>

            </div>
        </>
    );
};

export default IncomeExpenseCalculator;