import BalanceMaintainer from "./BalalanceMaintainer";
import React from "react";
import {useCalculator} from "../context/calculatorContextProvider";

const ExpenseCalculator = () => {
    const {appState, removeExpenseItem, addExpenseItem} = useCalculator();   
    const {expenseTotal,expenseItems} = appState;

    const title = 'Expense';
    const setItems = (itemObj) => {
        addExpenseItem(itemObj);
    }
    const removeItemHandler = (itemIndex) => {
        removeExpenseItem(itemIndex);
    }
    return (
        <BalanceMaintainer
            balance={expenseTotal}
            title={title}
            items={expenseItems}
            removeItem={removeItemHandler}
            setItems={setItems}
        />
    );
}
export default ExpenseCalculator;