import {useEffect, useRef, useState} from "react";
import {db} from '../../firebase/init';
import {collection, getDocs} from "@firebase/firestore";
import {useUser} from "../../context/useUser";
import {useCalculator} from "../../context/calculatorContextProvider";

const History = ({onHistorySelect}) => {
    const {user} = useUser();
    const {userDetails} = user;

    const {showDataForDate} = useCalculator();

    const yearRef = useRef();
    const monthsRef = useRef();

    const monthToDate = {
        '01': 'January',
        '02': 'February',
        '03': 'March',
        '04': 'April',
        '05': 'May',
        '06': 'June',
        '07': 'July',
        '08': 'August',
        '09': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'December',
    }
    const [monthDetailsToShow, setMonthDetailsToShow] = useState(null);

    const [usersPastYears, setUsersPastYears] = useState([])
    const [monthsForYear, setMonthsForYear] = useState([]);
    const collectionYears = `users/${userDetails.uid}/Year`

    const getPropsForMonths = async (year) => {
        setMonthDetailsToShow(null);
        setMonthsForYear([]);
        if (year === '') {
            setMonthsForYear([]);
            setMonthDetailsToShow(null);
            return;
        }

        let collectionMonthsForYear = collectionYears + `/${year}/Month`;

        let querySnapshot = await getDocs(collection(db, collectionMonthsForYear));
        let data = []

        querySnapshot.forEach((doc) => {
            let id = doc.id;
            let monthData = doc.data();
            data = [...data, {id, data: monthData}]
        })

        setMonthsForYear(data);
    }

    const getYears = async () => {
        let querySnapshot = await getDocs(collection(db, collectionYears));
        let data = []

        querySnapshot.forEach((doc) => {
            data = [...data, doc.id]
        })
        setUsersPastYears(data);
    }

    useEffect(() => {
        if (userDetails.uid === undefined) {
            return;
        }

        getYears();

    }, [])

    const formSubmitHandler = (e) => {
        e.preventDefault();
        showDataForDate(yearRef.current.value, monthsRef.current.value);
        onHistorySelect();
    };
    return (
        <>
            <form method={"POST"} action={'/'} onSubmit={formSubmitHandler}>
                <div className="flex gap-5 mt-2.5 mb-2.5">
                    <div className={"years w-full sm:w-1/2"}>
                        <select className={"text-black border-2 shadow border-black rounded p-2.5"} ref={yearRef} onChange={
                            (e) => {
                                //console.log( yearRef.current.value )
                                getPropsForMonths(yearRef.current.value);
                            }
                        }>
                            <option value={''} key={0}>{'Select Year'}</option>
                            {
                                usersPastYears.length > 0 &&
                                usersPastYears.map((year, index) => {
                                    return <option value={year} key={year}>{year}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="months w-full sm:w-1/2">
                        <select className={"text-black border-2 shadow border-black rounded p-2.5"}
                                ref={monthsRef}
                                onChange={(e) => {
                                    setMonthDetailsToShow(null);
                                    monthsForYear.forEach((month, index) => {
                                        if (month.id === monthsRef.current.value) {
                                            setMonthDetailsToShow(month);
                                        }
                                    })
                                }}
                        >
                            <option value='' key={0}>Select Month</option>
                            {
                                monthsForYear.length > 0 &&
                                monthsForYear.map((month, index) => {
                                    return <option value={month.id} key={month.id}>{monthToDate[month.id]}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className={"monthDetails"}>
                    {
                        monthDetailsToShow !== null &&
                        <div>
                            <p>Showing Details for {yearRef.current.value} / {monthToDate[monthDetailsToShow.id]} </p>
                            <div className={"flex-col gap-5"}>
                                <div className={"flex"}>
                                    <div className={"w-1/2"}>Income Total :</div>
                                    <div className={"text-right w-1/2"}>{
                                        monthDetailsToShow.data.incomeTotal !== undefined &&
                                        monthDetailsToShow.data.incomeTotal}</div>
                                </div>
                                <div className={"flex"}>
                                    <div className={"w-1/2"}>Expense Total :</div>
                                    <div className={"text-right w-1/2"}>{
                                        monthDetailsToShow.data.expenseTotal !== undefined &&
                                        monthDetailsToShow.data.expenseTotal}</div>
                                </div>
                                <div className={"flex"}>
                                    <div className={"w-1/2"}>Investment Total :</div>
                                    <div className={"text-right w-1/2"}>{
                                        monthDetailsToShow.data.investmentTotal !== undefined &&
                                        monthDetailsToShow.data.investmentTotal}</div>
                                </div>
                                <div className={"flex"}>
                                    <div className={"w-1/2"}><strong>Balance :</strong></div>
                                    <div className={"text-right w-1/2"}>{
                                        monthDetailsToShow.data.balance !== undefined &&
                                        monthDetailsToShow.data.balance}</div>
                                </div>
                            </div>
                            <input type="submit" value="Edit Data for this Date" className="bg-black text-white hover:bg-blue-400"/>
                        </div>
                    }
                </div>
            </form>

        </>
    );
}

export default History;