import {useUser} from "../context/useUser";
import {useRef, useState} from "react";

const PassWordForm = () => {
    const {updatePasswordHandler} = useUser();

    const newPassword = useRef(null);
    const newPasswordConfirm = useRef(null);


    const submitFormHandler = async (e) => {
        e.preventDefault();
        if (newPassword.current.value === newPasswordConfirm.current.value) {
            let response = await updatePasswordHandler(newPassword.current.value);
            //console.log('the message',response);
            if (response !== undefined && response.message !== undefined) {
                alert(response.message);
            }
        } else {
            alert('Passwords do not match');
        }
    }
    return (
        <form method={"POST"} action={""} onSubmit={submitFormHandler}>
            <div className={"flex-col p-5"}>
                <p>Update Password</p>
                <div className={"flex-col mt-2.5"}>
                    <input type="password" id={"password"} name={"password"} ref={newPassword} placeholder={"Password"} className={"text-black border-2 border-black rounded-lg"}/>
                    <input type="password" id={"passwordConfirm"} name={"passwordConfirm"} ref={newPasswordConfirm} className={"mt-5 text-black border-2 border-black rounded-lg"} placeholder={"Confirm Password"}/>
                    <input type="submit" value={"Save Password"} className={"btn bg-black text-white mt-5 rounded"}/>
                </div>
            </div>
        </form>
    )
}

const UserProfile = ({showForm}) => {
    const {user, updateProfileHandler} = useUser();
    const {userDetails} = user;

    const [formFields, setFormFields] = useState({
        email: userDetails.email,
        displayName: userDetails.displayName !== null ? userDetails.displayName : '',
        // photoURL: userDetails.photoURL
    });

    const onChangeHandler = (e) => {
        const {name, value} = e.target;
        setFormFields((prevFormFields) => {
            return {...prevFormFields, [name]: value}
        });

    }

    const submitHandler = async (e) => {
        e.preventDefault();
        let response = await updateProfileHandler(formFields);
        if (response.message !== undefined) {
            alert('User Details Updated');
        }
        //alert(response.message);
    }

    if (!showForm) {
        return ''
    }

    return (
        <>
            <div className={"flex-col p-5 pb-0 gap-5 border-2 rounded border-blue-300"}>
                <form className={"pt-0 mb-2.5"} method={"post"} action={"/"} onSubmit={submitHandler}>
                    {/*<div className={"flex gap-5 align-middle items-center mt-5"}>*/}
                    {/*    <label htmlFor="photoURL" className={"w-2/12"}>Profile Image</label>*/}
                    {/*    <input type="file" id={"photoURL"} name="photoURL" className={"w-10/12"}/>*/}
                    {/*</div>*/}
                    <div className={"sm:flex mb-2.5 items-center"}>
                        <label htmlFor="email" className={"sm:w-3/12"}>Email</label>
                        <input type="email" id={"email"} name="email" value={formFields.email} onChange={onChangeHandler} className={"sm:w-9/12 text-black border-2 border-black rounded-lg"}/>
                    </div>
                    <div className={"sm:flex mb-2.5"}>
                        <label htmlFor="displayName" className={"sm:w-3/12"}>Display Name</label>
                        <input type="text" id={"displayName"} name="displayName" value={formFields.displayName} onChange={onChangeHandler} className={"sm:w-9/12 text-black border-2 border-black rounded-lg"}/>
                    </div>
                    <div className={""}>
                        <input type={"submit"} className={"btn bg-black text-white mt-5 rounded"} value={"Update"}/>
                    </div>
                </form>
            </div>

            <div className={"flex-col gap-5 border-2 rounded border-blue-300 mt-3.5"}>
                <PassWordForm/>
            </div>
        </>


    );
}

export default UserProfile;