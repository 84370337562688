import {useRef, useState} from "react";
import {useUser} from "../context/useUser";

const UserResetPassword = () => {
    const emailRef = useRef();
    const {resetPasswordHandler} = useUser();
    const submitHandler = (e) => {
        e.preventDefault();
        let email = emailRef.current.value;
        if (email !== '') {
            resetPasswordHandler(email);
        }
    }
    return (
        <form method={"POST"} action={"/"} className={"flex-col"} onSubmit={submitHandler}>
            <label htmlFor={"resetEmail"}>
                Reset Email
            </label>
            <input type="email"
                   id={"resetEmail"}
                   ref={emailRef}
                   placeholder={"Enter Your E-mail Address"}
                   className={"border-black border-2 rounded-lg mt-2"}
            />
            <input type="submit"

                   value="Send Reset Email"
                   className="bg-black text-white transform transition-transform hover:-translate-y-1 rounded  mt-2.5"/>
        </form>
    );
}

export default UserResetPassword;