import {createContext, useContext, useEffect, useState} from "react";
import {fireBaseApp} from "../firebase/init";
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    updateProfile,
    signOut,
    updatePassword,
    sendPasswordResetEmail,
} from "@firebase/auth";

const auth = getAuth(fireBaseApp);

const UserContext = createContext({
    user: {
        isLoggedIn: false,
        userDetails: null,
    },
    loginHandler: async () => {
    },
    logOutUser: async () => {
    },
    updateProfileHandler: async () => {

    },
    updatePasswordHandler: async () => {

    },
    resetPasswordHandler: async () => {

    },
});

export const UserProvider = ({children}) => {
    const [user, setUser] = useState({isLoggedIn: false, userDetails: null});
    //@todo setToTrue
    const [loading, setLoading] = useState(true);

    const getFormattedUserDetails = (userDetailsObj, devMode = false) => {
        if (devMode) {
            return userDetailsObj;
        }

        return {
            uid: userDetailsObj.uid,
            emailVerified: userDetailsObj.emailVerified,
            displayName: userDetailsObj.displayName,
            email: userDetailsObj.email,
            photoURL: userDetailsObj.photoURL
        }
    }
    const loginHandler = async (email, password) => {
        let result = {
            status: false,
            msg: "Something has gone wrong",
        };

        await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {

                let formattedUserData = getFormattedUserDetails(userCredential.user)

                setUser({
                    isLoggedIn: true,
                    userDetails: formattedUserData,
                });

                result.status = true;
                result.msg = 'Successfully Logged In';

            })
            .catch((error) => {
                result.status = false;
                result.msg = error.message;
            });

        return result;
    };
    const logOutUser = async () => {
        signOut(auth).then(() => {
            setUser({
                isLoggedIn: false,
                userDetails: null,
            });
            //successfully signed out
        }).catch((error) => {
            console.log(error);
        });
    }
    const updateProfileHandler = async (fields) => {
        let result = {
            status: false,
            message: "details have not updated"
        }

        await updateProfile(auth.currentUser, fields).then(() => {
            result.status = true;
            result.message = "User successfully Updated"
        }).catch((e) => {
            result.status = false;
            result.message = e.message();
        })


        return result;
    }
    const updatePasswordHandler = async (newPassword) => {
        let response = {
            status: false,
            message: 'Password not updated'
        }

        await updatePassword(auth.currentUser, newPassword).then(
            () => {
                response.status = true;
                response.message = 'Password updated';
            }
        ).catch((e) => {
            // console.log(e.message);
            response.status = false;
            response.message = e.message;
        })

        return response;
    }
    const resetPasswordHandler = async (email) => {
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert('Check your email - to reset your password')
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
                alert(error.message);
            });
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                let formattedUserData = getFormattedUserDetails(user)
                setUser({
                    isLoggedIn: true,
                    userDetails: formattedUserData,
                });
            } else {
                setUser({
                    isLoggedIn: false,
                    userDetails: null,
                });
            }
            setLoading(false);
        });
        return unsubscribe;
    }, []);

    const userProviderValues = {
        user,
        loginHandler,
        logOutUser,
        updateProfileHandler,
        updatePasswordHandler,
        resetPasswordHandler
    }

    //firebase stuff goes here
    return <UserContext.Provider value={userProviderValues}>{!loading && children}</UserContext.Provider>
}

export const useUser = () => {
    return useContext(UserContext);
}