import React, {useState} from "react";
import {MdModeEdit, MdRemoveCircle} from "react-icons/md";
import {useCalculator} from "../context/calculatorContextProvider";

const ListItemEditForm = (props) => {
    const [formFieldValues, setFormFieldValues] = useState({
        itemName: props.itemName,
        itemType: props.itemType,
        itemAmount: props.itemAmount
    });

    const {updateItems} = useCalculator();

    const handleOnChange = (e) => {
        const {name, value} = e.target;
        setFormFieldValues(prevFormFieldValues => {
            const newFormFieldValues = {
                [name]: value
            };
            return {...prevFormFieldValues, ...newFormFieldValues};
        });
    }

    const handleFormUpdate = (e) => {
        e.preventDefault();
        let itemObj = {
            name: formFieldValues.itemName,
            type: props.itemsToUpdate === 'investment' ? props.itemsToUpdate : formFieldValues.itemType,
            amount: formFieldValues.itemAmount,
            date: props.date
        }
        updateItems(props.itemsToUpdate, props.itemId, itemObj);
        props.formUpdated()
        // props.updateListItem(props.itemId, formFieldValues);
    }

    return (
        <form action="" method="post" className="p-5 border-white mb-5 border-2 text-black" onSubmit={handleFormUpdate}>
            <div className="col-12">
                <input
                    type="text"
                    name="itemName"
                    value={formFieldValues.itemName}
                    className="form-control"
                    onChange={handleOnChange}
                />
            </div>
            {
                props.itemsToUpdate !== 'investment' &&
                <div className="col-12 mt-2.5">
                    <select
                        name="itemType"
                        value={formFieldValues.itemType}
                        className="form-control"
                        required={"required"}
                        onChange={handleOnChange}
                    >
                        <option value="">Select Type</option>
                        <option value="monthly">Monthly</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Daily</option>
                    </select>
                </div>
            }

            <div className="col-12 mt-2.5">
                <input
                    type="text"
                    name="itemAmount"
                    value={formFieldValues.itemAmount}
                    className="form-control"
                    onChange={handleOnChange}
                />
            </div>
            <div className="flex mt-5 gap-4">
                <button className="btn bg-black text-white w-1/3">Update</button>
                <button className="btn bg-red-600 text-white w-1/3" onClick={props.cancelEdit}>Cancel</button>
            </div>
        </form>
    );
}
const ListItem = (props) => {
    const {name, amount, type, date, itemType} = props;
    const [editItemNow, setEditItemNow] = useState(false);
    let dateToDisplay = '';
    if (date !== undefined) {
        const storedDate = new Date(date);
        dateToDisplay = new Intl.DateTimeFormat('en-us', {day: 'numeric'}).format(storedDate);
    }


    const handleRemove = (e) => {
        e.preventDefault();
        props.removeItem(props.id);
    };

    const handleEditItem = (e) => {
        setEditItemNow(true);
    }
    const handleCancelEdit = (e) => {
        setEditItemNow(false);
    }

    const formUpdated = () => {
        setEditItemNow(false);
    }

    const removeItem = (props.itemRemovable) ? <button className="btn btn-outline-danger remove-item" onClick={handleRemove}>
        <MdRemoveCircle className={"text-white hover:text-red-400 hover:bg-white"}/>
    </button> : '';

    const editItem = (props.itemEditable) ? <button
        className="btn remove-item"
        onClick={handleEditItem}>
        <MdModeEdit className={"text-white hover:text-green-800 hover:bg-white"}/>
    </button> : ''

    if (editItemNow) {
        return (
            <li>
                <ListItemEditForm
                    itemId={props.id}
                    date={dateToDisplay}
                    itemName={name}
                    itemType={type}
                    itemAmount={amount}
                    itemsToUpdate={itemType}
                    cancelEdit={handleCancelEdit}
                    formUpdated={formUpdated}
                />
            </li>
        )
    }

    return (
        <li className={"flex balance-item justify-around"}>
            <span className="w-2/12 sm:w-1/6 text-center">{dateToDisplay}</span>
            <span className="list-name w-1/6 sm:w-1/4">{name}
            </span>
            {
                type
                &&
                <span className="list-type w-1/5 sm:w-1/5 hidden sm:block">{type}</span>

            }
            <span className="list-amount relative w-1/4 sm:w-1/5">
                {amount}
            </span>
            <span>
                {removeItem}
                {editItem}
            </span>

        </li>
    )
};

export default ListItem;